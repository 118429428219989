/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import PythonLogo from "../assets/images/tech/python.svg";
import NodejsLogo from "../assets/images/tech/nodejs.svg";
import VueLogo from "../assets/images/tech/vue-js.svg";
import ReactLogo from "../assets/images/tech/react.svg";
import AngularLogo from "../assets/images/tech/angular.svg";
export default function Tech() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.python.org/" target="_blank">
              <img className="h-12" src={PythonLogo} alt="Python" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://nodejs.org/en/" target="_blank">
              <img className="h-12" src={NodejsLogo} alt="Node.js" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://vuejs.org/" target="_blank">
              <img className="h-12" src={VueLogo} alt="Vue.js" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://reactjs.org/" target="_blank">
              <img className="h-12" src={ReactLogo} alt="React.js" />
            </a>
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://angular.io/" target="_blank">
              <img className="h-12" src={AngularLogo} alt="Angular" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
